import { withStyles, WithStyles } from '@mui/styles';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { PickerProfileStore } from '../Stores/PickerProfileStore';
import { NotificationStrategyStore } from 'src/ZoneManagement/Stores/NotificationStrategyStore';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { Fade, Grid, IconButton, ListItemText, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import { NotificationStrategyForm } from 'src/ZoneManagement/Components/NotificationStrategyForm';
import { StyledSelectField } from 'src/Common/Components/Forms/StyledSelectField';
import { OrderPreparationStepType, StartupStrategy, StartupStrategyType } from '@ekkogmbh/apisdk';
import { StyledCheckbox } from 'src/Common/Components/Forms/StyledCheckbox';
import { StyledTextField } from 'src/Common/Components/Forms/StyledTextField';
import { Add, ArrowLeft, ArrowRight, Remove } from '@mui/icons-material';
import { StyledFormHeader } from 'src/Common/Components/Forms/StyledFormHeader';

const styles = FormStyles;

interface PickerProfileStartupFormState {
  currentPreparationIndex?: number;
}

interface PickerProfileStartupFormProps extends WithStyles<typeof styles> {
  errorCallback: () => void;
}

interface PickerProfileStartupFormStores {
  apiStore: ApiStore;
  pickerProfileStore: PickerProfileStore;
  notificationStrategyStore: NotificationStrategyStore;
}

@inject('api', 'pickerProfileStore', 'notificationStrategyStore')
@observer
class PickerProfileStartupFromComponent extends Component<
  PickerProfileStartupFormProps,
  PickerProfileStartupFormState
> {
  public state: PickerProfileStartupFormState = {
    currentPreparationIndex: undefined,
  };

  get stores(): PickerProfileStartupFormProps & PickerProfileStartupFormStores {
    return this.props as PickerProfileStartupFormProps & PickerProfileStartupFormStores;
  }

  public componentDidMount(): void {
    const { pickerProfileStore } = this.stores;

    const preparationStepCount = pickerProfileStore.state.orderPreparationSteps?.length;

    if (preparationStepCount !== undefined && preparationStepCount !== 0) {
      this.setState({ currentPreparationIndex: preparationStepCount - 1 });
    }
  }

  private onStrategySelect = (name: StartupStrategy['name']) => {
    const { pickerProfileStore } = this.stores;

    pickerProfileStore.setState({ startupStrategy: { name, config: {} } });
  };

  public renderStartupFrom(): JSX.Element {
    const { pickerProfileStore } = this.stores;
    const { startupStrategy } = pickerProfileStore.state;

    let configForm: JSX.Element;
    switch (startupStrategy?.name as StartupStrategyType | undefined) {
      case StartupStrategyType.NEXT:
        const config = startupStrategy!.config;
        const amount: number = config.amount ?? 1;
        const onceOnly: boolean = config.onceOnly !== undefined ? config.onceOnly : true;
        const sortBy: string = config.sortBy !== undefined ? config.sortBy : 'created_at';
        const sortDirection: string = config.sortDirection !== undefined ? config.sortDirection : 'descending';

        // FIXME: possibly want this as API enums
        const sortByOptions = ['created_at', 'name'].map((name: string) => (
          <MenuItem key={`sort-by-${name}`} value={name}>
            <ListItemText primary={name} />
          </MenuItem>
        ));
        const sortDirectionOptions = ['ascending', 'descending'].map((direction: string) => (
          <MenuItem key={`sort-dir-${direction}`} value={direction}>
            <ListItemText primary={direction} />
          </MenuItem>
        ));

        configForm = (
          <Grid container item xs={12} spacing={1} alignItems={'flex-start'} alignContent={'stretch'}>
            <Grid item xs={6}>
              <StyledSelectField
                label={'sort-by'}
                value={sortBy}
                onChange={(e) => {
                  pickerProfileStore.setState({
                    startupStrategy: {
                      ...startupStrategy!,
                      config: {
                        ...config,
                        sortBy: e.target.value as string,
                      },
                    },
                  });
                }}
              >
                {sortByOptions}
              </StyledSelectField>
            </Grid>
            <Grid item xs={6}>
              <StyledSelectField
                label={'sort-direction'}
                value={sortDirection}
                onChange={(e) => {
                  pickerProfileStore.setState({
                    startupStrategy: {
                      ...startupStrategy!,
                      config: {
                        ...config,
                        sortDirection: e.target.value as string,
                      },
                    },
                  });
                }}
              >
                {sortDirectionOptions}
              </StyledSelectField>
            </Grid>
            <Grid item xs={6}>
              <StyledCheckbox
                label={'single use blueprints only'}
                value={onceOnly}
                onChange={(_e) => {
                  pickerProfileStore.setState({
                    startupStrategy: {
                      ...startupStrategy!,
                      config: {
                        ...config,
                        onceOnly: !onceOnly,
                      },
                    },
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <StyledTextField
                label={'amount'}
                type={'number'}
                value={amount}
                onChange={(e) => {
                  pickerProfileStore.setState({
                    startupStrategy: {
                      ...startupStrategy!,
                      config: {
                        ...config,
                        amount: Number(e.target.value) > 0 ? Number(e.target.value) : 1,
                      },
                    },
                  });
                }}
              />
            </Grid>
          </Grid>
        );
        break;
      case StartupStrategyType.SCAN:
      case StartupStrategyType.SELECT:
      case undefined:
      default:
        configForm = <></>;
    }

    return (
      <Stack alignItems={'stretch'}>
        <StyledSelectField
          label={'Startup Strategy'}
          value={startupStrategy?.name ?? ''}
          onChange={(e) => this.onStrategySelect(e.target.value as StartupStrategy['name'])}
        >
          {Object.values(StartupStrategyType).map((name: StartupStrategyType, index: number) => (
            <MenuItem key={index} value={name}>
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </StyledSelectField>
        {configForm}
      </Stack>
    );
  }

  public renderOrderPreparationForm(): JSX.Element {
    const { currentPreparationIndex } = this.state;
    const { pickerProfileStore } = this.stores;
    const { orderPreparationSteps } = pickerProfileStore.state;

    const listControls: JSX.Element = (
      <Stack
        direction={'row'}
        spacing={1}
        sx={{
          justifyContent: 'flex-start',
          alignItems: 'baseline',
        }}
      >
        <IconButton
          disabled={(currentPreparationIndex ?? 0) == 0}
          onClick={() => this.setState({ currentPreparationIndex: currentPreparationIndex! - 1 })}
        >
          <ArrowLeft fontSize={'inherit'} />
        </IconButton>
        <Typography>
          {orderPreparationSteps !== undefined && orderPreparationSteps.length > 0
            ? (currentPreparationIndex! + 1).toString() + '/' + orderPreparationSteps.length.toString()
            : ''}
        </Typography>
        <IconButton
          disabled={
            currentPreparationIndex === undefined || currentPreparationIndex + 1 === orderPreparationSteps!.length
          }
          onClick={() => this.setState({ currentPreparationIndex: currentPreparationIndex! + 1 })}
        >
          <ArrowRight fontSize={'inherit'} />
        </IconButton>
        <IconButton
          onClick={() => {
            const steps = pickerProfileStore.state.orderPreparationSteps ?? [];
            const newLength = steps.push({
              type: OrderPreparationStepType.ANNOTATION_SCAN,
              instruction: '',
              configuration: { key: '' },
            });
            pickerProfileStore.setState({ orderPreparationSteps: [...steps] });
            this.setState({ currentPreparationIndex: newLength - 1 });
          }}
        >
          <Tooltip title={'add new step'}>
            <Add />
          </Tooltip>
        </IconButton>
        <IconButton
          disabled={currentPreparationIndex == undefined}
          onClick={() => {
            const steps = pickerProfileStore.state.orderPreparationSteps ?? [];
            if (steps.length == 1) {
              pickerProfileStore.setState({ orderPreparationSteps: undefined });
              this.setState({ currentPreparationIndex: undefined });
            } else {
              steps.splice(currentPreparationIndex!, 1);
              pickerProfileStore.setState({ orderPreparationSteps: [...steps] });
              this.setState({ currentPreparationIndex: currentPreparationIndex! - 1 });
            }
          }}
        >
          <Tooltip title={'remove selected step'}>
            <Remove />
          </Tooltip>
        </IconButton>
      </Stack>
    );

    return (
      <Stack direction={'column'}>
        <StyledFormHeader
          label={'Preparation Steps (Optional)'}
          tooltip={'steps to perform by a picker after creating an order and before starting it'}
        />
        {listControls}
        {currentPreparationIndex !== undefined && (
          <>
            <StyledTextField
              disabled
              label={'Type'}
              value={OrderPreparationStepType.ANNOTATION_SCAN}
              onChange={(_e) => console.log('this is disabled since there are no options yet')}
            />
            <StyledTextField
              label={'Instruction'}
              value={orderPreparationSteps![currentPreparationIndex].instruction}
              onChange={(e) => {
                orderPreparationSteps![currentPreparationIndex].instruction = e.target.value as string;
                pickerProfileStore.setState({
                  orderPreparationSteps: [...orderPreparationSteps!],
                });
              }}
            />
            <StyledTextField
              label={'Annotation Key'}
              value={orderPreparationSteps![currentPreparationIndex].configuration.key}
              onChange={(e) => {
                orderPreparationSteps![currentPreparationIndex].configuration.key = e.target.value as string;
                pickerProfileStore.setState({
                  orderPreparationSteps: [...orderPreparationSteps!],
                });
              }}
            />
          </>
        )}
      </Stack>
    );
  }

  public render(): JSX.Element {
    return (
      <Fade in={true} timeout={1000}>
        <Grid container spacing={1} justifyContent={'center'}>
          <Grid item xs={6}>
            <Stack direction={'column'} spacing={2}>
              <StyledFormHeader label={'Startup Strategy'} tooltip={'determines how a picker creates new orders'} />
              {this.renderStartupFrom()}
              {this.renderOrderPreparationForm()}
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <StyledFormHeader
              label={'Notification Strategies (Optional)'}
              tooltip={'is prioritized over associated zone notification strategies'}
            />
            <NotificationStrategyForm />
          </Grid>
        </Grid>
      </Fade>
    );
  }
}

const StyleWrapped = withStyles(styles)(PickerProfileStartupFromComponent);

export const PickerProfileStartupForm = StyleWrapped;
